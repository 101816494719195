/** 
 * Función que devuelve un array de items dependiendo de si el usuario es Admin o no.
 */
export function getHomePages(isAdmin) {
  const commonItems = [
      {
        route: "/quest",
        iconName:'cilCheckCircle',
        title: "Cuestionarios",
        text: isAdmin ? "En esta vista se puede realizar una administración y gestión de las los cuestionarios." : "En esta vista se puede revisar el listado de los cuestionarios y la información de cada uno.",
        goTo: "Ir a Cuestionarios"
      },
      {
        route: "/diseases",
        iconName:'cilHospital',
        title: "Enfermedades",
        text: isAdmin ? "En esta vista se puede realizar una administración y gestión de las enfermedades." : "En esta vista se puede revisar el listado de las enfermedades y la información de cada una.",
        goTo: "Ir a Enfermedades"
      },
      {
        route: "/categories",
        iconName:'cilApps',
        title: "Categorías",
        text: isAdmin ? "En esta vista se puede realizar una administración y gestión de las categorías." : "En esta vista se puede revisar el listado de las categorías y la información de cada una.",
        goTo: "Ir a Categorías"
      },
      {
        route: "/type-categories",
        iconName:'cilOptions',
        title: "Tipos de categoría",
        text: isAdmin ? "En esta vista se puede realizar una administración y gestión de los tipos de categoría." : "En esta vista se puede revisar el listado de los tipos de categoría y la información de cada uno.",
        goTo: "Ir a Tipos de categoría"
      },
    ]

    const userTypeSpecificItems = isAdmin ? 
      [ 
        {
          route: "/psychologists",
          iconName:'cilUser',
          title: "Psicólogos",
          text:"En esta vista se puede realizar una administración y gestión de los psicólogos.",
          goTo: "Ir a Psicólogos"
        },
      ]       

    :
      []
      
    return userTypeSpecificItems.concat(commonItems)
}