<template>
    <CRow class="row-items">
        <CCol sm="4" class="componentes" v-for="({route, iconName, title, text, goTo}, index) in appItems" :key="index">
            <CCard class="h-100 pt-2">
            <router-link :to="route" class="icon-link">
                <CIcon :name="iconName"/>
                <CCardBody class="pb-1" >
                <CCardTitle>{{title}}</CCardTitle>
                <CCardText>
                    <p class="truncate-multiline">{{ text }}</p></CCardText>
                </CCardBody>
                <CButton :to="route" class="link-button btn-info">{{goTo}}</CButton>
            </router-link>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
export default {
    name: 'PageCard',
    props: {
        appItems: {
            type: Array,
            required: true,
        },
    }
}
</script>

<style scoped>
.row-items{
  padding-top: 20px;
}
.componentes .card:hover {
  transform: scale(.95);
  transition: transform 0.2s;
}
.componentes .card{
  cursor: pointer;
  transition: transform 0.2s;
  margin-bottom: 0;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-bottom: 0;
  min-height: 85px;
}
.componentes .card .link-button{
  margin-top: 10px;
  width: 100%;
  background-color: #D22020 !important;
  border-color: #D22020 !important;
}
.link-button:hover {
  margin-top: 10px;
  width: 100%;
  background-color: #414449 !important;
  border-color: #414449 !important;
}
.icon-link {
  color: inherit;
}
.componentes .card a{
  text-decoration: none !important;
}
CCardImage {
  width: 100%;
  height: 180;
}
.componentes {
  margin-bottom: 25px;
}

svg {
  display: block;
  margin: 0 auto;
  padding: 10px 10px;
  transition: transform 0.2s;
  width: 100%;
  height: 180px;
}

.icon-link {
  color: inherit;
}

.componentes .icon-link svg{
  height: 150px;
  color: #414449;
  width: 150px;
}

@media (max-width: 1100px) {
  .componentes .icon-link svg{
    height: 100px !important;
    width: 100px !important;
  }
}
</style>